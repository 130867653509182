import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function NavButton({ text, to }) {
  return (
    <Link to={to}>
      <Button
        sx={{ color: 'white', fontSize: 'min(3.0vw, 16px)', minWidth: '0px' }}>
        {text}
      </Button>
    </Link>
  );
}
