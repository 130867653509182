import React from 'react';
import { Link } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function ContentCard({ image, alt, title, subtitle, to }) {
  return (
    <Link style={{ textDecoration: 'none' }} to={to}>
      <Card sx={{ maxWidth: 550, margin: 'auto', marginTop: '1rem' }}>
        <CardActionArea>
          <CardMedia alt={alt} component="img" height="300" image={image} />

          <CardContent>
            <Typography component="div" gutterBottom variant="h5">
              {title}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {subtitle}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
