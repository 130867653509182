import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { JAIME_CALLEJON_HIERRO } from '../../constants/NavBar';
import {
  SUBTITLE_1,
  TEXT_1,
  TEXT_2,
  TEXT_3,
  TEXT_4,
  TEXT_4_LINK_1,
  TEXT_5,
  TITLE_1,
} from '../../constants/projects/SpaceExploration';
import HABITAT_EXTERIOR_IMAGE from '../../static/images/HabitatExterior.png';
import HABITAT_INTERIOR_IMAGE from '../../static/images/HabitatInterior.png';
import HABITAT_TRAJECTORY_IMAGE from '../../static/images/HabitatTrajectory.png';
import BackToProjects from '../../utils/Content/BackToProjects';
import ContentContainer from '../../utils/Content/ContentContainer';
import ContentImage from '../../utils/Content/ContentImage';
import ContentText from '../../utils/Content/ContentText';
import ContentTitleSubtitle from '../../utils/Content/ContentTitleSubtitle';

const SpaceExploration = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  return (
    <ContentContainer>
      <BackToProjects />
      <ContentTitleSubtitle subtitle={SUBTITLE_1} text={TITLE_1} />
      <ContentText text={TEXT_1} />
      <ContentText text={TEXT_2} />
      <div style={{ width: '100%' }}>
        <div style={{ width: '48%', float: 'left' }}>
          <ContentImage
            alt={JAIME_CALLEJON_HIERRO}
            src={HABITAT_EXTERIOR_IMAGE}
          />
        </div>
        <div style={{ marginLeft: '52%' }}>
          <ContentImage
            alt={JAIME_CALLEJON_HIERRO}
            src={HABITAT_INTERIOR_IMAGE}
          />
        </div>
      </div>
      <ContentText text={TEXT_3} />
      <ContentImage
        alt={JAIME_CALLEJON_HIERRO}
        src={HABITAT_TRAJECTORY_IMAGE}
      />
      <Box>
        <Typography
          component="div"
          sx={{ margin: '1rem 0rem', fontSize: 'min(3.0vw, 16px)' }}
          variant="h6">
          {TEXT_4}{' '}
          <a
            href="https://aero.umd.edu/news/story/two-umd-teams-take-best-in-theme-at-nasarsquos-rascal-competition"
            rel="noreferrer"
            target="_blank">
            {TEXT_4_LINK_1}
          </a>
          {TEXT_5}
        </Typography>
      </Box>
    </ContentContainer>
  );
};

export default SpaceExploration;
