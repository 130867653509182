import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ContentTitle({ text }) {
  return (
    <Box sx={{ margin: '2rem 0rem' }}>
      <Typography
        component="div"
        sx={{ fontSize: 'min(5.0vw, 28px)' }}
        variant="h5">
        {text}
      </Typography>
    </Box>
  );
}
