import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ContentText = ({ text }) => {
  return (
    <Box>
      <Typography
        component="div"
        sx={{ margin: '1rem 0rem', fontSize: 'min(3.0vw, 16px)' }}
        variant="h6">
        {text}
      </Typography>
    </Box>
  );
};

export default ContentText;
