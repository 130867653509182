import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  LINK_1,
  SUBTITLE_1,
  TEXT_1,
  TEXT_2,
  TEXT_3,
  TEXT_LINK_1,
  TITLE_1,
} from '../../constants/projects/FastAndFrosty';
import BackToProjects from '../../utils/Content/BackToProjects';
import ContentContainer from '../../utils/Content/ContentContainer';
import ContentText from '../../utils/Content/ContentText';
import ContentTitleSubtitle from '../../utils/Content/ContentTitleSubtitle';
import ContentVideo from '../../utils/Content/ContentVideo';

export default function FastAndFrosty() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  return (
    <ContentContainer>
      <BackToProjects />
      <ContentTitleSubtitle subtitle={SUBTITLE_1} text={TITLE_1} />
      <ContentText text={TEXT_1} />
      <ContentVideo videoId="bp7WCJZp3Eg?si=EBJv1zOjfK-B09nr" />
      <Box>
        <Typography
          component="div"
          sx={{ margin: '1rem 0rem', fontSize: 'min(3.0vw, 16px)' }}
          variant="h6">
          {TEXT_2}
          <a href={LINK_1} rel="noreferrer" target="_blank">
            {TEXT_LINK_1}
          </a>
          {TEXT_3}
        </Typography>
      </Box>
    </ContentContainer>
  );
}
