export const TITLE_1 = `Durable Low-Mass Lunar Surface Habitat`;

export const SUBTITLE_1 = `Awarded Best in Theme in NASA RASC-AL 2021 Competition`;

export const TEXT_1 = `For my capstone Aerospace Engineering project, I worked with a team of University 
of Maryland students to compete in the NASA RASC-AL design competition. The project was to design a 30 
day mission on the Moon for 2 astronauts on a 1 billion dollar budget by 2026. Teams would need to design 
a Lunar habitat to shelter the astronauts, and a deployment strategy to get it safely to the Moon. After 
much consideration, we developed the mobile habitat below.`;

export const TEXT_2 = `The main objective of most manned space missions is to conduct scientific research. 
We wanted our astronauts to have access to as much of the Lunar surface as possible. Mobile habitats allow 
astronauts to explore a much greater area of the Lunar surface, and allow future Lunar manned missions to 
reuse this habitat at different locations on the Moon. A lot of effort went into designing this mobility 
system and ensuring to had sufficient power to travel meaningful distances of the Lunar surface.`;

export const TEXT_3 = `For this project, I focused mainly on mission planning and analysis. I was responsible 
for the overarching design decisions that were necessary to execute the mission itself. These included choosing 
our launch vehicle, planning orbital trajectories, designing our landing and deployment strategy on the Moon, 
and choosing our power generation and storage capabilities. I also developed a comprehensive plan to extend 
this mission to Mars on a larger budget and timeframe. NASA plans to use the Moon as a launchpad for future 
missions on Mars, so it was important for our mission to be compatible with larger interplanetary objectives. 
Below is a sample of my work: a 30 day travel path on the Martian surface with designated stops for scientific 
research and testing.`;

export const TEXT_4 = `Our project was awareded Best in Theme for the NASA RASC-AL 2021 competition. We were 
featured in our `;

export const TEXT_4_LINK_1 = `school newspaper`;

export const TEXT_5 = `, and congratulated by the Dean of Engineering at the University of Maryland. 
It was a fantastic end to my career as an Aerospace Engineer, and my last project before transitioning fully 
into Software Engineering.`;
