import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import {
  HOME,
  HOME_ROUTE,
  JAIME_CALLEJON_HIERRO,
  PROJECTS,
  PROJECTS_ROUTE,
  RESUME,
  RESUME_ROUTE,
} from '../constants/NavBar';
import JAIME_IMAGE from '../static/images/Jaime.png';
import NavButton from '../utils/Nav/NavButton';
import NavImage from '../utils/Nav/NavImage';
import NavText from '../utils/Nav/NavText';

const NavBar = () => (
  <Box>
    <AppBar position="static">
      <Toolbar>
        <NavText text={JAIME_CALLEJON_HIERRO} />
        <NavButton text={HOME} to={HOME_ROUTE} />
        <NavButton text={PROJECTS} to={PROJECTS_ROUTE} />
        <NavButton text={RESUME} to={RESUME_ROUTE} />
        <NavImage alt={JAIME_CALLEJON_HIERRO} src={JAIME_IMAGE} />
      </Toolbar>
    </AppBar>
  </Box>
);

export default NavBar;
