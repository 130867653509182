export const ABOUT_ME_TITLE = 'About Me';

export const ABOUT_ME_TEXT_1 = `Hello! I am a full-stack engineer at Capital One, currently working in the Intent 
  Setup division of Card Tech. I work with other engineers to develop the software 
  tools that allow us to configure new credit cards products for customers. I focus 
  on developing features that enable faster and more reliant creation of products. 
  Before joining this team, I also worked in the Rewards division of Card Tech. My 
  team was responsible for a considerable portion of the frontend and content delivery 
  APIs within Capital One Rewards. I worked on increasing the reliability and resiliency 
  of our services to ensure consistent access to our millions of daily active users. `;

export const ABOUT_ME_TEXT_2 = `I joined Capital One through the Developer Academy program, which is a 
  6-month coding bootcamp for non computer science students. In this program, I learned the fundamentals 
  of software engineering and began my journey as a full-stack developer. Meanwhile, I worked part time 
  on a Masters in Computer Science at Georgia Tech, which gave me the opportunity to take lots of cool 
  classes related to Machine Learning and AI.`;

export const ABOUT_ME_TEXT_3 = `My background is in aerospace engineering. I worked on NASA sponsored 
  projects throughout college, focusing on autonomous robotic technologies for space stations and 
  extraterrestrial habitat design. I was awarded the NASA RASC-AL design two years in a row, 
  which helped the University of Maryland’s Aerospace Department climb to the national Top 10. 
  I graduated Magna Cum Laude in 2021 with a B.S in Aerospace Engineering and a B.S. in Mathematics. 
  Throughout college, I also worked for NASA’s Heliophysics department to design software tools for 
  space weather analysis. My work was awarded the American Geophysical Union's Grand Prize Student 
  Visualization Award and a $6,000 cash prize.`;
