import * as React from 'react';

import {
  ABOUT_ME_TEXT_1,
  ABOUT_ME_TEXT_2,
  ABOUT_ME_TEXT_3,
  ABOUT_ME_TITLE,
} from '../constants/Home';
import { JAIME_CALLEJON_HIERRO } from '../constants/NavBar';
import JAIME_MAIN_IMAGE from '../static/images/jaime-home.jpg';
import ContentContainer from '../utils/Content/ContentContainer';
import ContentImage from '../utils/Content/ContentImage';
import ContentSocialIcons from '../utils/Content/ContentSocialIcons';
import ContentText from '../utils/Content/ContentText';
import ContentTitle from '../utils/Content/ContentTitle';

const Home = () => (
  <ContentContainer>
    <ContentTitle text={ABOUT_ME_TITLE} />
    <ContentText text={ABOUT_ME_TEXT_1} />
    <ContentText text={ABOUT_ME_TEXT_2} />
    <ContentImage alt={JAIME_CALLEJON_HIERRO} src={JAIME_MAIN_IMAGE} />
    <ContentText text={ABOUT_ME_TEXT_3} />
    <ContentSocialIcons />
  </ContentContainer>
);

export default Home;
