import React from 'react';

export default function ContentVideo({ videoId }) {
  const videoURL = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%' /* 16:9 aspect ratio, adjust as needed */,
      }}>
      <iframe
        allowFullScreen
        frameBorder="0"
        src={videoURL}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        title="YouTube video player"
      />
    </div>
  );
}
