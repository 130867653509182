import React, { useState } from 'react';

import GMAIL_IMAGE from '../../static/images/gmail.jpeg';
import INSTAGRAM_IMAGE from '../../static/images/instagram.jpeg';
import LINKEDIN_IMAGE from '../../static/images/linkedin.jpeg';

const SocialIconButton = ({ href, alt, src, hoveredIcon, setHoveredIcon }) => {
  const iconStyles = {
    width: 'min(11.0vw, 100px)', // Set a fixed width for the buttons
    height: 'min(10.0vw, 90px)', // Set a fixed height for the buttons
    margin: '0 1vw', // Add spacing between icons
    transition: 'transform 0.2s', // Add a smooth transition effect
    cursor: 'pointer', // Add a pointer cursor to indicate clickability
    border: 'none', // Remove border
    outline: 'none', // Remove outline
    background: 'none', // Remove background
  };

  const enlargeIcon = {
    transform: 'scale(1.1)', // Enlarge the icon on hover
  };

  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <button
        style={{ ...iconStyles, ...(hoveredIcon === alt ? enlargeIcon : null) }}
        type="button"
        onBlur={() => setHoveredIcon(null)}
        onFocus={() => setHoveredIcon(alt)}
        onMouseOut={() => setHoveredIcon(null)}
        onMouseOver={() => setHoveredIcon(alt)}>
        <img alt={alt} src={src} style={{ height: '100%' }} />
      </button>
    </a>
  );
};

const ContentSocialIcons = () => {
  const containerStyles = {
    display: 'flex',
    flexDirection: 'row', // Arrange items horizontally
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
    height: 'min(13.0vw, 120px)', // Set a fixed height
    margin: '1vw',
  };

  const [hoveredIcon, setHoveredIcon] = useState(null);

  return (
    <div style={containerStyles}>
      <SocialIconButton
        alt="Gmail"
        hoveredIcon={hoveredIcon}
        href="mailto:jcallejonh@gmail.com"
        setHoveredIcon={setHoveredIcon}
        src={GMAIL_IMAGE}
      />
      <SocialIconButton
        alt="LinkedIn"
        hoveredIcon={hoveredIcon}
        href="https://www.linkedin.com/in/jaime-callejon-933a32190/"
        setHoveredIcon={setHoveredIcon}
        src={LINKEDIN_IMAGE}
      />
      <SocialIconButton
        alt="Instagram"
        hoveredIcon={hoveredIcon}
        href="https://www.instagram.com/jcallejonh/"
        setHoveredIcon={setHoveredIcon}
        src={INSTAGRAM_IMAGE}
      />
    </div>
  );
};

export default ContentSocialIcons;
