import React from 'react';

import resumePDF from '../static/pdfs/Resume-Jaime-Callejon.pdf';

const Resume = () => {
  return (
    <div
      style={{
        height: 'max(100vw, 600px)',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <object
        data={resumePDF}
        style={{ width: '100%', flex: '1' }}
        type="application/pdf">
        <p>
          Your browser does not support PDFs. You can{' '}
          <a href={resumePDF}>download the PDF</a> instead.
        </p>
      </object>
    </div>
  );
};

export default Resume;
