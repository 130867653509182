import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import NavBar from './pages/NavBar';
import NoPage from './pages/NoPage';
import Projects from './pages/Projects';
import FastAndFrosty from './pages/projects/FastAndFrosty';
import FluxRopes from './pages/projects/FluxRopes';
import SpaceExploration from './pages/projects/SpaceExploration';
import Resume from './pages/Resume';

const App = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route path="/projects">
          <Route element={<Projects />} index />
          <Route element={<FastAndFrosty />} path="fast-and-frosty" />
          <Route element={<FluxRopes />} path="flux-ropes" />
          <Route element={<SpaceExploration />} path="space-exploration" />
        </Route>
        <Route element={<Resume />} path="/resume" />
        <Route element={<NoPage />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
