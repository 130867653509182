import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ContentTitleSubtitle({ text, subtitle }) {
  return (
    <Box sx={{ margin: '2rem 0rem' }}>
      <Typography
        component="div"
        sx={{ fontSize: 'min(5.0vw, 23px)' }}
        variant="h5">
        {text}
      </Typography>
      <Typography
        component="div"
        sx={{ color: 'gray', fontSize: 'min(3.0vw, 15px)' }}
        variant="h6">
        {subtitle}
      </Typography>
    </Box>
  );
}
