export const TITLE_1 = `Flux Rope Visualization`;

export const SUBTITLE_1 = `Awarded Grand Prize by the American Geophysical Union's 2020 Visualization Competition ($6000)`;

export const TEXT_1 = `In the Summer of 2019, I began working at NASA's Goddard Space Flight Center in the Heliophysics
Science division. I joined a team that specialized in the study of Interplanetary Coronal 
Mass Ejections (ICMEs), which are large expulsions of plasma and magnetic fields from the Sun. Our head scientist, 
Teresa Nieves-Chinchilla, had developed a complex model to explain the magnetic field data patterns recorded 
by our scientific spacefraft. I was asked to develop a tool to make her complicated equations easier to visualize.`;

export const TEXT_2 = `The model hypothesized cylindrical magnetic structures moving through space. To validate it, 
we compared Teresa's research against real data collected by our spacecraft. I created a Python API to fetch 
scientific and spatial data from five prominent satellites (WIND, Solar Orbiter, Stereo Ahead, Stereo Behind, 
Parker Solar Probe). We used this API to compare Teresa's ICME model (in pink) with the spacecraft data (black) and 
generated hundreds of graphs like the one below:
`;

export const TEXT_3 = `Teresa's 3D model prompted the need for immersive visualization. She entrusted me with recruiting 
an additional engineer to take on the massive project. I brought in Eddie, a skilled engineer and friend from my 
Aerospace Engineering program at the University of Maryland. Together, we created the Flux Rope Visualizer, a tool 
that enables users to explore time-based space data from our leading Heliophysics satellites. You can browse through
different points in history to visualize the ICME events captured by all 5 spacecraft. You can also compare this
data to Teresa's cylindrical magnetic structure model.`;

export const TEXT_4 = `I presented my work at the American Geophysical Union (AGU) 2020 Fall Meeting, `;

export const TEXT_LINK_1 = `Royal Astronomical Society (RAS) poster competition`;
export const LINK_1 = `https://ras.ac.uk/sites/default/files/posters/AGU%20Poster%20-%20jcallejonh.pdf`;

export const TEXT_5 = `, and 
`;

export const TEXT_LINK_2 = `published at the Committee of Space Research (COSPAR) 43rd Scientific Assembly`;
export const LINK_2 = `https://ui.adsabs.harvard.edu/abs/2021cosp...43E1761C/abstract`;

export const TEXT_6 = `. I was awarded 
the Grand Prize for the American Geophysical Union's 2020 Visualization Competition, and was invited to scientific 
conferences across the US and Australia. After I left the team, Eddie was awarded John Mather's Noble Scholars for 
his contributions to the project. Our work is now 
`;

export const TEXT_LINK_3 = `publicly hosted on NASA's website.`;
export const LINK_3 = `https://parker.gsfc.nasa.gov/extensions/FRviewer/`;
