import React from 'react';
import { Typography } from '@mui/material';

export default function NavText({ text }) {
  return (
    <Typography
      component="div"
      sx={{ fontSize: 'min(3.0vw, 18px)', flexGrow: 1 }}>
      {text}
    </Typography>
  );
}
