import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  LINK_1,
  LINK_2,
  LINK_3,
  SUBTITLE_1,
  TEXT_1,
  TEXT_2,
  TEXT_3,
  TEXT_4,
  TEXT_5,
  TEXT_6,
  TEXT_LINK_1,
  TEXT_LINK_2,
  TEXT_LINK_3,
  TITLE_1,
} from '../../constants/projects/FluxRopes';
import FLUX_ROPE_GRAPH_IMAGE from '../../static/images/flux-rope-2D-graph.png';
import BackToProjects from '../../utils/Content/BackToProjects';
import ContentContainer from '../../utils/Content/ContentContainer';
import ContentImage from '../../utils/Content/ContentImage';
import ContentText from '../../utils/Content/ContentText';
import ContentTitleSubtitle from '../../utils/Content/ContentTitleSubtitle';
import ContentVideo from '../../utils/Content/ContentVideo';

const FluxRopes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  return (
    <ContentContainer>
      <BackToProjects />
      <ContentTitleSubtitle subtitle={SUBTITLE_1} text={TITLE_1} />
      <ContentText text={TEXT_1} />
      <ContentText text={TEXT_2} />
      <ContentImage alt="Flux Rope 2D Graph" src={FLUX_ROPE_GRAPH_IMAGE} />
      <ContentText text={TEXT_3} />

      <ContentVideo videoId="u5Uek5ub35E?si=5vw6nXr_eS7JTJxi" />
      <Box>
        <Typography
          component="div"
          sx={{ margin: '1rem 0rem', fontSize: 'min(3.0vw, 16px)' }}
          variant="h6">
          {TEXT_4}{' '}
          <a href={LINK_1} rel="noreferrer" target="_blank">
            {TEXT_LINK_1}
          </a>
          {TEXT_5}
          <a href={LINK_2} rel="noreferrer" target="_blank">
            {TEXT_LINK_2}
          </a>
          {TEXT_6}
          <a href={LINK_3} rel="noreferrer" target="_blank">
            {TEXT_LINK_3}
          </a>
        </Typography>
      </Box>
    </ContentContainer>
  );
};

export default FluxRopes;
