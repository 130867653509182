import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

export default function NavImage({ alt, src }) {
  return (
    <Box sx={{ margin: '10px' }}>
      <Avatar
        alt={alt}
        src={src}
        sx={{ width: 'min(10.0vw, 50px)', height: 'min(10.0vw, 50px)' }}
      />
    </Box>
  );
}
