import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import FAST_AND_FROSTY_IMAGE from '../static/images/FastNFrosty.png';
import FLUX_ROPE_VISUALIZER_IMAGE from '../static/images/FluxRopeVisualizer.png';
import RASC_AL_IMAGE from '../static/images/RASC-AL.png';
import ContentCard from '../utils/Content/ContentCard';
import ContentContainer from '../utils/Content/ContentContainer';

const Projects = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  const FAST_AND_FROSTY_SNIPPET = `Single player racing game developed in Unity. Speed your way through the jungle, 
  collect special items, and use your power ups to beat the AI. Let the best win!`;

  const FLUX_ROPE_VISUALIZER_SNIPPET = `Interactive 3D visualization to monitor historic space weather events. 
  Visualize the data captured by our very own NASA spacecraft!`;

  const RASC_AL_SNIPPET = `Research completed throughout my days as an Aerospace Engineer. Focus on deep space 
  exploration and crewed missions to the Moon and Mars.`;

  return (
    <ContentContainer>
      <ContentCard
        alt="fastNFrosty"
        image={FAST_AND_FROSTY_IMAGE}
        subtitle={FAST_AND_FROSTY_SNIPPET}
        title="Fast & Frosty"
        to="/projects/fast-and-frosty"
      />
      <ContentCard
        alt="fluxRopeVisualizer"
        image={FLUX_ROPE_VISUALIZER_IMAGE}
        subtitle={FLUX_ROPE_VISUALIZER_SNIPPET}
        title="Flux Rope Visualization"
        to="/projects/flux-ropes"
      />
      <ContentCard
        alt="Space Exploration"
        image={RASC_AL_IMAGE}
        subtitle={RASC_AL_SNIPPET}
        title="Space Exploration"
        to="/projects/space-exploration"
      />
    </ContentContainer>
  );
};

export default Projects;
