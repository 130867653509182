export const TITLE_1 = `Fast & Frosty`;

export const SUBTITLE_1 = `Final Project of my Video Game Design Class at Georgia Tech`;

export const TEXT_1 = `As I was completing my Masters in Computer Science at Georgia Tech, I took a Video Game
Design class that taught students the basics of game development in Unity. For my final project, I created Fast
and Frosty - a single player game where you race against AI-powered snowmen in a jungly terrain. I built the AI, 
animations, race tracks, and game logic from scratch, as well as other features like custom items and powerups.
You can throw snowballs at other players, get speed boosts, and grapple to trees to speed up your snowman. Get 
ready for some exciting head-to-head challenges and frosty adventures!`;

export const TEXT_2 = `The game is 
`;

export const TEXT_LINK_1 = `available on the Unity Store`;
export const LINK_1 = `https://play.unity.com/mg/other/fast-and-frosty-webgl`;

export const TEXT_3 = ` for anyone to play! Due to size limitations, I 
had to disable the Single Race option, so you have to use the Campaign mode to start a new race. Use arrows to 
move, Space for power ups, and Shift to grapple on trees. Good luck!
`;
